<template>
    <div>
        <div class="container m-auto max-w-md">
            <div class="flex flex-col gap-4 items-center">
                <img src="@/assets/icons/logo-tripwe-new.svg" class="w-40"/>
                <div class="flex flex-row justify-between gap-4">
                    <div>
                        <qrcode
                        :value="detailInv.nobukti"
                        :options="{ width: 180 }"
                        tag="img"
                        class="border border-gray-200 rounded-2xl shadow-lg"
                    ></qrcode>
                    </div>
                    <div class="flex flex-col gap-2 place-content-center">
                        <span class="font-extrabold text-2xl">{{detailInv.namapaket}}</span>
                        <span class="font-extrabold text-xl">{{detailInv.namatoko}}</span>
                        <span class="font-bold text-lg">Booking no: {{detailInv.nobukti}}</span>
                        <span class="font-bold text-lg">Status: {{getCurrentStatus}}</span>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between mt-6">
                <div class="flex flex-col gap-2">
                    <span class="font-bold">Name</span>
                    <span class="font-bold">Starting Date</span>
                    <span class="font-bold">Starting Time</span>
                    <span class="font-bold">Meeting Point</span>
                    <span class="font-bold">Booked Units</span>
                    <span class="font-bold">Trip Duration</span>
                    <span class="font-bold">Payment Date</span>
                    <span class="font-bold">Payment Method</span>
                </div>
                <div class="flex flex-col gap-2">
                    <span class="font-bold">: {{detailInv.namauser}}</span>
                    <span class="font-bold">: {{$moment(detailInv.tgl_akhir).format("dddd, DD MM YYYY")}}</span>
                    <span class="font-bold">: {{detailInv.jam_awal}}</span>
                    <span class="font-bold">: {{detailInv.dermaga}}</span>
                    <span class="font-bold">: {{detailInv.unit}}</span>
                    <span class="font-bold">: {{detailInv.durasi}}</span>
                    <span class="font-bold">: {{$moment(detailInv.waktu_bayar).format("dddd, DD MM YYYY")}}</span>
                    <span class="font-bold">: {{paymentMode}}</span>
                </div>
            </div>
            <div class="border border-black rounded-2xl mt-6">
                <div class="flex flex-col gap-2 p-4">
                    <div class="flex flex-row justify-between" v-for="(psn, idxpsn) in detailInv.daftarpesanan" :key="idxpsn">
                        <span class="font-bold text-black text-md">{{psn.namapaket}} {{psn.qtyunit | toCurrency}}x {{psn.harga | toCurrency}}/hour</span>
                        <span class="font-bold text-black text-md">{{psn.jumlah | toCurrency}}</span>
                    </div>
                    <div class="flex flex-row justify-between" v-if="detailInv.diskon > 0">
                        <span class="font-bold text-black text-md">Voucher</span>
                        <span class="font-bold text-black text-md">{{detailInv.diskon | toCurrency}}</span>
                    </div>
                    <div class="flex flex-row justify-between" v-if="isBankTransfer">
                        <span class="font-bold text-black text-md">Unique Code</span>
                        <span class="font-bold text-black text-md">{{detailInv.unik | toCurrency}}</span>
                    </div>
                </div>
                <div class="font-bold px-4 py-2 bg-black text-white rounded-br-2xl rounded-bl-2xl">
                    <div class="flex flex-row justify-between">
                        <span class="">Total Payment</span>
                        <span class="" v-if="isBankTransfer">{{detailInv.jumlahakhir | toCurrency}}</span>
                        <span class="" v-if="!isBankTransfer">{{detailInv.jml_dskn | toCurrency}}</span>
                    </div>
                </div>
            </div>
            <div class="text-center font-bold">
                Note: Show the QR Code at the Meeting Point to start your trip
            </div>
        </div>
    </div>
</template>

<script>
  
  export default {
    name: 'InvoiceDownload',
    data: () => ({
      detailInv: {},
    }),
    computed: {
        getCurrentStatus(){
            if(this.detailInv.status == '0'){
                return 'Unpaid';
            }else if(this.detailInv.status == '1' && this.detailInv.status_cair == '0'){
                return 'On Process';
            }else if(this.detailInv.status == '1' && this.detailInv.status_cair == '1'){
                return 'Finished';
            }else if(this.detailInv.status == '12' || this.detailInv.status == '4'){
                return 'Canceled';
            }
        },
        paymentMode(){
            var reff = this.detailInv.tipe_reff;
            var str = 'Bank Transfer';
            if(reff == 'BCA' || reff == 'PMT' || reff == 'BRI'){
                str = 'Bank Transfer';
            }else if(reff == "TWPAY"){
                str = 'TripwePay';
            }else if(reff == "CC"){
                str = 'Credit Card';
            }
            return str;
        },
        isBankTransfer(){
            var reff = this.detailInv.tipe_reff;
            if(reff == 'BCA' || reff == 'PMT' || reff == 'BRI'){
                return true;
            }else {
                return false;
            }
        },
        isCreditCard(){
            var reff = this.detailInv.tipe_reff;
            if(reff == 'CC'){
                return true;
            }else {
                return false;
            }
        },
    },
    created () {
        if(Object.keys(this.$store.state.detailInv) != 0){
            this.detailInv = this.$store.state.detailInv;
            this.$store.commit("setDetailInv", {});
            setTimeout(() => {
                window.print();
            }, 1000);
        }else{
            window.close();
        }
    },
    methods: {
    }
  }
</script>